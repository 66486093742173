@import 'src/common/CommonVariables';

#bulk-predictions {
  .footerContent button {
    text-transform: none !important;
  }

  .menu-item {
    display: flex;
    align-items: center;
    gap: $s;
  }

  .bulk-predictions-sub-block-header {
    font-size: $ft-body__small !important;
    border-bottom: solid 1px $base-30 !important;
    padding-bottom: 0.2em !important;
  }

  .bulk-predictions-assets-block > * {
    margin-bottom: 1em !important;
  }

  .bulk-predictions-body {
    padding-left: 1em !important;
    padding-right: 1em !important;
    padding-top: 1em !important;
    gap: 4% !important;
  }

  .bulk-predictions-body .bulk-predictions-section-one {
    width: 66% !important;

    .bulk-predictions-assets-block {
      min-width: 50% !important;
    }
  }

  .bulk-predictions-body .bulk-predictions-section-two {
    width: 30% !important;
  }

  .bulk-predictions-sub-sections {
    gap: 20px;
  }

  .bulk-predictions-body .options-button {
    display: flex !important;
    justify-content: flex-end !important;
    width: 100% !important;
  }

  .bulk-predictions-body .options-button button {
    border-radius: 16px !important;
    min-width: 48px !important;
  }

  .bulk-predictions-body .bulk-predictions-label {
    font-family: ft-system-bold !important;
  }

  .bulk-predictions-sub-block > * {
    font-size: $ft-body__medium !important;
  }

  .MuiTooltip-tooltip {
    max-width: 200px !important;
    font-size: $ft-body__xsmall !important;
    background-color: $base-90 !important;
    color: white !important;
    border-radius: 8px !important;
  }

  .MuiTooltip-arrow {
    color: $base-90 !important;
  }

  .bulk-predictions-body-container {
    overflow-y: auto;

    .body-header-container {
      background-image: url('../../assets/imgs/modalHeaderBackground.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: inherit;
      height: 80px;
      border-radius: 8px 8px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bulk-prediction-header-title {
        margin: 0px 24px;
        display: flex;
        flex-direction: row;
        justify-content: space-between !important;
        align-items: flex-end;
        gap: 20px;
        width: 100%;

        .title-1 {
          display: flex;
          gap: 8px;
          padding: 8px 16px;
          border-radius: 12px;
          background: rgba(255, 255, 255, 0.25);
          backdrop-filter: blur(16.5px);
          color: white;
        }
      }
    }

    .body-header-container-sub-title {
      display: flex;
      flex-direction: column;
      padding: 16px 24px;
      gap: 10px;
      background: $base-10;

      .sub-title-1 {
        font-weight: 800;
      }

      .sub-title-2 {
        font-weight: 200;
      }
    }

    .bulk-predictions-body-tables {
      height: auto;

      .bulk-predictions-switch-head {
        display: flex;
        justify-content: flex-end;
        flex-direction: row;
        width: 100%;
        margin-bottom: 30px;
        gap: 15px;
        align-items: center;

        .bulk-predictions-switch-head-title {
          font-weight: 800;
          font-size: 1rem;
        }
      }
    }
  }

  .bulk-predictions-body-container::-webkit-scrollbar {
    width: 15px;
    background-color: transparent;
  }

  .bulk-predictions-body-container::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .bulk-predictions-body-container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 5px solid white !important;
  }

  .bulk-predictions-body-container::-webkit-scrollbar-track-piece:start {
    background: transparent;
  }
}

@media (max-height: 900px) {
  #bulk-predictions {
    .bulk-predictions-body-container .bulk-predictions-body-tables {
      max-height: 275px;
    }
    .sub-title-1,
    .sub-title-2 {
      font-size: 0.85rem !important;
    }
  }
}
