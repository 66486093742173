@import 'src/common/CommonVariables';

.context {
  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  &__legend {
    display: flex;
    align-items: baseline;
    gap: 15px;
    color: $base-80;
    font-size: $ft-body__small;
    div {
      display: flex;
      gap: 5px;
      align-items: center;
    }
    span {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    #succeed-span {
      background-color: $success;
    }
    #warning-span {
      background-color: $warning;
    }
    #error-span {
      background-color: $alert;
    }
  }
  &__info {
    width: 30%;
    background-color: $info-95;
    .alertBox {
      padding: 12px 20px 12px 20px !important;
    }
  }
  .preview-table-row {
    td:nth-child(3),
    th:nth-child(3) {
      max-width: 340px !important;
      .chip.cursor-pointer > .custom-icon {
        height: 20px;
        width: 20px;
      }
    }

    td:nth-child(4),
    th:nth-child(4) {
      min-width: 20px;
    }

    th:nth-child(4) {
      > * {
        float: right;
      }
    }

    td:nth-child(4) {
      > * {
        margin: auto;
      }
    }

    th:nth-child(5) > *,
    td:nth-child(5) > * {
      float: left;
    }
  }
  .wrapp-preview-table {
    max-height: calc(100vh - 450px) !important;
  }
}

@media (max-height: 850px) {
  .context {
    .wrapp-preview-table {
      max-height: calc(100vh - 420px) !important;
    }
  }
}
