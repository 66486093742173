@import 'src/common/CommonVariables';

.chip {
  background-color: $base-20 !important;
  max-width: fit-content !important;
  border: solid 1px $base-30 !important;
  border-radius: 4px !important;
}

.tabs {
  .Mui-selected {
    color: $base-90;
    font-weight: bold;
  }
  .MuiTabs-indicator {
    background-color: $accent;
  }
  &__element {
    color: $base-70 !important;
    font-weight: bold !important;
    text-transform: capitalize !important;
  }
}

.chip.temporarily-stored.MuiChip-root {
  font-weight: 700;
  background-color: $accent-92 !important;
  color: $accent !important;
  border-radius: 16px !important;
}

.tags {
  display: flex;
  gap: 5px;
}

.patternImage {
  width: 40px;
  height: 40px;
  margin-left: 15px;
  margin-right: 15px;
  border-radius: 8px;
}

.chips-wrapper {
  margin-top: 0.5em;
  width: 95% !important;
  max-width: 300px;
  max-height: 55px;
  height: 100%;
  overflow: auto;
  padding-right: 1em;
  .chip {
    margin: 0.2em;
  }
  .chip > div {
    display: flex;
    align-items: center;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.doted-options-button,
.regular-button {
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
}

.doted-options-button {
  border: none !important;
  background-color: transparent;
  padding: 5px;
}

.doted-options-button:hover {
  background-color: white;
}

.doted-options-button:focus-within,
.regular-button:focus-within {
  outline: none !important;
  border-color: $accent !important;
  box-shadow: $focusBox-shadow-color !important;
}

.sm-modal-body-title {
  color: $base-70 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: -0.14px !important;
}

.sm-modal-title-header {
  color: $base-90 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: bold !important;
  line-height: 28px !important;
}

.sm-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.sm-modal-button {
  border-radius: 16px !important;
  color: #fff !important;
  background-color: $base-90 !important;
  border-color: $base-30 !important;
  text-transform: capitalize;
  box-shadow: none !important;
}
.sm-modal-button:hover {
  background-color: #ffffff !important;
  color: $base-90 !important;
}
.sm-modal-button:disabled {
  color: $base-30 !important;
  background-color: $base-20 !important;
}

.sm-modal-wrapper {
  width: 400px;
  background: white;
  padding: 24px;
  border-radius: 21px;
}

.md-modal-wrapper {
  padding: 30px 24px 24px;
  gap: 24px;
  width: 498px;
  background: white;
  border-radius: 40px;
}

.display-column {
  display: flex;
  flex-direction: column;
  word-break: break-all;
}

.display-row-start {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.display-row {
  display: flex;
  flex-direction: row;
}

.center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-center {
  display: flex;
  justify-content: center;
}

.align-center {
  display: flex;
  align-items: center;
}

.justify-space-between {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
}

.justify-space-around {
  display: flex;
  justify-content: space-around !important;
  width: 100%;
}

.option-basic-menu .MuiMenu-list {
  padding: 0 !important;
}

.input-error,
.input-error fieldset {
  border-color: $alert;
}
.input-error .Mui-focused .MuiOutlinedInput-notchedOutline,
.fieldset:focus-visible {
  border-color: $alert !important;
}

.errorMsg {
  color: $alert;
  margin: 5px 5px 5px 0 !important;
}

.primary-color,
.primary-color-no-hover {
  background-color: $base-90 !important;
  color: white !important;
}

.primary-color:hover {
  background-color: white !important;
  color: $base-90 !important;
}

.secondary-color,
.secondary-color-no-hover {
  background-color: white !important;
  color: $base-90 !important;
}

.secondary-color:hover {
  background-color: $base-90 !important;
  color: white !important;
}

button:disabled {
  & svg > * {
    stroke: $base-60 !important;
  }
}

.light-icon,
.light-icon-no-hover,
.dark-icon:hover {
  & svg > * {
    stroke: white !important;
  }
}

.dark-icon,
.dark-icon-no-hover,
.light-icon:hover {
  & svg > * {
    stroke: $base-90 !important;
  }
}

.grey-icon-no-hover {
  & svg > * {
    stroke: $base-60 !important;
  }
}

.light-thin-grey-icon-no-hover {
  & svg > * {
    stroke: $base-40 !important;
  }
}

.light-fill-icon,
.light-fill-icon-no-hover,
.dark-fill-icon:hover {
  & svg > * {
    fill: white !important;
  }
}

.dark-fill-icon,
.dark-fill-icon-no-hover,
.light-fill-icon:hover {
  & svg > * {
    fill: $base-90 !important;
  }
}

.grey-fill-icon-no-hover {
  & svg > * {
    fill: $base-60 !important;
  }
}

.asset-title {
  font-family: ft-system-bold !important;
  font-size: 24px !important;
  color: $base-90;
  line-height: 30px !important;
  width: 100%;
}

.MuiPagination-root {
  button {
    border: 1px solid $base-40;
    color: #344054;

    &:hover {
      background-color: $base-10 !important;
    }
  }

  .Mui-selected {
    background-color: $base-30 !important;
  }
}

.MuiPagination-ul > :first-child > button {
  border-radius: 8px 0px 0px 8px !important;
}

.MuiPagination-ul > :last-child > button {
  border-radius: 0px 8px 8px 0px !important;
}

.render-view {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: [header] auto [body] 1fr [footer] 85px !important;
  &__header {
    grid-row: header;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 40px);
    padding: 20px 20px 0 20px;
  }
  &__body {
    grid-row: body;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 20px 20px 20px 20px !important;
  }
  &__footer {
    grid-row: footer;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    padding: 20px;
    border-top: solid 1px $base-30;
    display: flex;
    justify-content: space-between !important;
  }
}

.spinner-box {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.header-title {
  color: $base-90;
  font-size: 18px;
  font-style: normal;
  font-weight: bold !important;
  line-height: 28px;
}

.textArea {
  border-radius: 8px;
  border: 1px solid $base-30;
  > * {
    font-size: $ft-body__large;
    font-family: ft-system-regular;
  }
  .ql-container {
    height: auto;
    border: none !important;
    .ql-editor {
      min-height: 130px !important;
      max-height: 45vh !important;
      overflow-y: auto;
    }
  }
  .ql-toolbar {
    border: none !important;
    border-bottom: 1px solid $base-30 !important;
  }
}

.textArea:focus-within {
  outline: none !important;
  border: 1px solid !important;
  border-color: $accent !important;
  box-shadow: $focusBox-shadow-color !important;
}

.textArea-display {
  .ql-container {
    border: none !important;
    .ql-editor {
      padding: 0 !important;
    }
  }
}
