@import 'src/common/CommonVariables';

.MuiTooltip-popper {
  .MuiTooltip-tooltip,
  span::before {
    background-color: $base-90;
  }
}

.custom-table {
  border-collapse: collapse;
  border-spacing: 0px;
  border-radius: 10px;
  width: 100%;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
  font-size: $ft-body__small;

  .MuiRadio-root:hover {
    background-color: transparent !important;
  }

  .MuiRadio-root {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  tr:not(button) .custom-icon {
    height: 24px;
    width: 24px;
  }

  tr div:is(.tooltipBox) .custom-icon {
    height: auto;
  }

  tr:not(.select) .custom-icon {
    & svg > * {
      stroke: $base-80;
    }
  }

  th,
  td {
    min-width: 115px;
    max-width: 350px;
    font-family: $ft-system-regular;
    text-align: left;
    vertical-align: top;
  }

  th {
    padding: $m $ml;
    font-weight: 500;
  }

  tbody {
    td {
      padding: 16px;
    }
    tr {
      border-bottom: 1px solid $base-30;
    }
  }

  tbody:not(.no-hover) {
    tr {
      cursor: pointer;
    }
    tr:not(.no-hover):not(.select):hover {
      td {
        color: $base-80 !important;
        background-color: $base-30 !important;
        transition: background-color 0.3s;
      }
      div,
      p {
        color: $base-80;
      }
      td.select * {
        color: $base-0;
      }
    }
  }

  thead {
    tr {
      border-radius: 10px 10px 0 0;
      th:first-child {
        border-radius: 10px 0 0 0;
      }
      th:last-child {
        border-radius: 0 10px 0 0;
      }
    }
    th.theme-default {
      color: $base-90;
      background-color: $base-20;
      border-bottom: 1px solid $base-30;
    }
    th.theme-highlight-1 {
      color: $base-90;
      background-color: $base-40;
      border-bottom: 1px solid $base-30;
    }
    th.theme-highlight-2 {
      color: $base-10;
      background-color: $base-80;
    }
    th.theme-highlight-3 {
      color: $base-0;
      background-color: $accent;
    }
    th.theme-highlight-2,
    th.theme-highlight-3 {
      border-bottom: 1px solid $base-30;
      .custom-icon > svg > * {
        stroke: white !important;
      }
    }
  }

  thead,
  tbody:not(.no-hover) {
    .select,
    .select td,
    .select th,
    th.select,
    td.select {
      background-color: $base-80 !important;
      color: $base-0;

      .MuiRadio-root,
      div,
      p {
        color: $base-0;
      }

      svg * {
        stroke: $base-0;
      }

      button svg * {
        stroke: $base-80;
      }

      .chip > span {
        color: $base-80;
      }
    }
  }

  /* Theme classes for modifying rows inside custom-table */
  tbody {
    td.theme-default {
      color: $base-80;
      background-color: $base-0;
    }
    td.theme-highlight-1 {
      color: $base-80;
      background-color: $base-10;
    }
    td.theme-highlight-2 {
      color: $base-80;
      background-color: $base-20;
    }
    td.theme-highlight-3 {
      color: $base-80;
      background-color: $accent-92;
    }
  }

  tfoot {
    border-top: 1px solid $base-30;
    tr {
      border-radius: 10px 10px;
      td:first-child {
        border-radius: 0 0 0 10px;
      }
      td:last-child {
        border-radius: 0 0 10px 0;
      }
    }
  }

  th:first-child,
  td:first-child {
    padding-left: 35px !important;
  }

  .chip {
    color: $base-80;
    font-size: $ft-body__small;
  }
}
