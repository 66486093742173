@import 'src/common/CommonVariables';

#details-assets-component {
  display: grid;
  grid-template-rows: [header] auto [body] 1fr [footer] 85px !important;

  &__body {
    overflow-y: auto;
    .details-content-header {
      display: flex;
      padding-top: $m;
      gap: $l;

      .asset-title {
        flex-grow: 1;
        word-break: break-all;
      }
    }

    .details-content-wrapp {
      display: flex;
      gap: 24px;
      height: calc(100% - 86px);
      .details-sub-sections {
        display: flex;
        flex-direction: row;
        gap: 12px;
        padding-top: 1em;
      }
      .asset-description {
        .ql-container {
          font-size: $ft-body__medium !important;
          font-family: ft-system-regular !important;
          color: $base-70;
          word-break: break-word;
        }
        margin-top: 1em;
        margin-bottom: 1em;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        button {
          width: max-content;
          min-width: fit-content;
        }
      }
    }

    .wrapp-preview-table,
    .wrapp-rows-table {
      max-height: calc(100vh - 420px);
    }
    .context {
      .wrapp-preview-table {
        max-height: calc(100vh - 280px) !important;
      }
    }
    .detail-asset-content {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
    }

    .chat-open {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      height: 40px;
    }

    iframe {
      margin-top: 5px;
      border: none;
      overflow: scroll;
      width: 100%;
      height: 100%;
    }

    .details-section-one {
      width: calc(100vw - 200px); // All spaces and paddings
      display: flex;
      flex-direction: column;
      word-break: break-all;
    }
    .details-section-one > div:last-child {
      padding-bottom: 10px !important;
    }

    .details-section-two {
      display: flex;
      gap: 10px;
      padding-top: 6px;
      margin-bottom: 16px;
      font-family: $ft-system-regular;
      color: $base-80;
      align-items: flex-start;
      > div {
        display: flex;
        align-items: center;
      }
      .details-access-type {
        display: flex;
        gap: 6px;
        > * {
          color: $base-80;
        }
        svg {
          max-width: 14px !important;
        }
      }
      .details-tags {
        flex-wrap: wrap;
        max-width: 80%;
        > * {
          width: max-content;
          color: $base-80;
        }
      }
      .date {
        display: flex;
        align-items: center;
        padding-top: 5px;
        p {
          word-break: keep-all;
          color: $base-60;
        }
      }
      .MuiTypography-root {
        font-size: $ft-body__small !important;
      }
    }

    .options-button {
      display: flex !important;
      justify-content: flex-end !important;
      gap: 8px;
    }

    .insight-label {
      font-family: ft-system-bold !important;
    }
    .context {
      margin-top: 20px;
    }

    .menu-item {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .details-block {
      display: flex;
      width: 100%;
      background-color: $base-10;
      border-radius: 10px;
      border: 1px solid $base-30;

      .details-preview-block {
        width: 100%;
        display: contents;
        justify-content: center;
        align-items: center;

        > div .preview-table,
        .preview-rows-table {
          border-radius: 10px;
        }
      }
    }

    .details-head {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
      padding-top: 12px;
      .details-head-title {
        width: 70%;
      }
    }
  }

  &__footer {
    .details-footer-left {
      display: flex;
      gap: 10px;
    }
    .details-select {
      font-size: 12px;
      font-weight: 700;
      border-radius: 8px;
      .MuiSelect-select {
        padding: 11.5px 14px;
        padding-right: 40px !important;
      }
    }
    .footerContent button {
      text-transform: none !important;
    }
  }

  &__footer.details-edit-footer {
    display: flex;
    justify-content: flex-end !important;
  }

  .access-items-center {
    display: flex;
    align-items: center;
  }

  .details-sub-block-header {
    font-size: $ft-body__small !important;
    border-bottom: solid 1px $base-30 !important;
    padding-bottom: 0.2em !important;
  }

  .insight-sub-block > * {
    font-size: $ft-body__medium !important;
  }

  .MuiTooltip-tooltip {
    max-width: 200px !important;
    font-size: $ft-body__xsmall !important;
    background-color: $base-90 !important;
    color: white !important;
    border-radius: 8px !important;
  }

  .MuiTooltip-arrow {
    color: $base-90 !important;
  }
}

@media (min-height: 700px) {
  #details-assets-component {
    &__body {
      .wrapp-rows-table,
      .wrapp-preview-table {
        max-height: calc(100vh - 400px);
      }
      .context {
        .wrapp-preview-table {
          max-height: calc(100vh - 300px) !important;
        }
      }
    }
  }
}
