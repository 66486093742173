@import 'src/common/CommonVariables';

@mixin background-opacity($color, $opacity: 0.3) {
  background: $color; /* The Fallback */
  background: rgba($color, $opacity);
}

.table-label {
  font-size: $ft-body__medium;
}

.preview-rows-table-row {
  .preview-flex {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
  }
}

.rows-first-column {
  padding-left: 35px !important;
}

.preview-context {
  font-size: $ft-body__medium !important;
  color: $base-90;
  text-align: left;
}

.preview-rows-table-pagination {
  margin-top: 1em;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.preview-rows-table tbody::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

.preview-rows-table tbody::-webkit-scrollbar-track {
  background-color: transparent;
}

.preview-rows-table tbody::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.preview-rows-table tbody::-webkit-scrollbar-track-piece:start {
  background: transparent;
}

.disregard-row {
  background-color: $base-20;
  opacity: 0.5;
}

.merge-disregard-row {
  @include background-opacity($alert, 0.2);
}

.row-edited {
  border-left: 2px solid $accent !important;
}

.row-duplicated {
  @include background-opacity($color-9, 0.2);
}

.row-added {
  border-left: 2px solid $color-5 !important;
}

thead {
  .disregard-row {
    border-bottom: 2px solid purple !important;
    background-color: $base-20;
    opacity: 0.5;
  }
  .merge-disregard-row {
    background-color: $alert !important;
    color: white !important;
  }
  .row-duplicated {
    background-color: $color-9 !important;
  }
}

.merged-columns-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.preview-rows-table::-webkit-scrollbar {
  width: 15px;
  background-color: transparent;
}

.preview-rows-table::-webkit-scrollbar-track {
  border-radius: 10px;
}

.preview-rows-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.allowed-bulk-row {
  display: flex;
  align-items: center;
  background-color: $base-30 !important;
  margin: auto;
  height: 100%;
  width: auto;
  cursor: pointer;

  .bulk-allowed-icon {
    display: flex;
    width: 32px;
    height: 100%;
    min-height: max-content;
    padding: 0 5px 0 5px;
    align-items: center;
  }

  div:not(.select) .bulk-allowed-icon svg * {
    stroke: $base-90 !important;
  }

  div.select .bulk-allowed-icon svg * {
    stroke: white !important;
  }

  .bulk-allowed-icon:hover {
    & svg * {
      stroke: $accent !important;
    }
  }
  div > * {
    width: 100%;
  }
  td {
    width: 100% !important;
    display: contents;
  }
}

.summary-layer-body-container {
  .select {
    background-color: $base-80 !important;
    * {
      color: white !important;
    }
    .bulk-allowed-icon {
      & svg * {
        stroke: white !important;
      }
    }
  }
}
