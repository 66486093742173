@import 'src/common/CommonVariables';

.merge-dataset-preview {
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-top: 15px;
  height: calc(100% - 70px);

  .merge-pointer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .merge-body-header {
    margin-bottom: 24px;
    display: flex;
    width: 100%;
    gap: 15px;
    align-items: center;
    .column-details-select {
      height: fit-content;
    }
  }
  .wrapp-rows-table {
    height: calc(100vh - 400px);
    max-height: inherit;
  }
}
